import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch, } from "react-redux";
import {
    submit_feedback,
} from "../reducers/chat";
import { 
    ENTER, 
    MODEL_INPUT_PLACEHOLDER, 
    FEEDBACK_MESSAGE_BAD 
} from "../constants/Constants"
import './style.css'

function FeedbackModal(props) {
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState(FEEDBACK_MESSAGE_BAD);
    const submitFeedbackFunction = (data) => {
        if (data) {
            dispatch(submit_feedback({ id: props?.message?.id, feedback: data }));
            setFeedback(FEEDBACK_MESSAGE_BAD);
        }
        else {
            dispatch(submit_feedback({ id: props?.message?.id, feedback: FEEDBACK_MESSAGE_BAD }));
        }
    }
    return (
        <Modal {...props} aria-labelledby="example-modal-sizes-title-sm" centered>
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    Feedback
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <TextField
                        onChange={e => setFeedback(e.target.value)}
                        id="input"
                        label={MODEL_INPUT_PLACEHOLDER}
                        variant="outlined"
                        className='modelInput'
                        onKeyPress={(e) => {
                            if (e.key === ENTER) {
                                submitFeedbackFunction(e.target.value)
                            }
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                className='modelButton' 
                variant="contained" 
                onClick={() => submitFeedbackFunction(feedback)}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default FeedbackModal;
