import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CommentIcon from "@mui/icons-material/Comment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FeedbackModal from "../feedbackModal";
import { useDispatch, useSelector } from "react-redux";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Form,
  Dropdown,
} from "react-bootstrap";
import { logoutUser } from "../../reducers/login";
import SendIcon from "@mui/icons-material/Send";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";

import { getChat, submit_feedback, chat_history } from "../../reducers/chat";
import {
  ENTER,
  SEARCH_INPUT_PLACEHOLDER,
  FEEDBACK_MESSAGE_GOOD,
  FEEDBACK_MESSAGE_BAD,
  MODEL_CLICKAWAY,
  LOGO_URL,
  APP_NAME,
} from "../../constants/Constants";

import "../style.css";
import LogoBar from "../LogoBar/LogoBar";
import History from "../History/History";

const ChatBox = () => {
  const dispatch = useDispatch();
  const { chat, chat_loading, feedback_res, history_lists } = useSelector(
    (state) => state.chat
  );
  console.log("CCCCCCCCCCCC",history_lists)
  const [chatList, setChatList] = useState([]);
  const [query, setQuery] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [appPath, setAppPath] = React.useState("");
  const [tabActive, setTabActive] = useState("history");
  const [historyActive, setHistoryActive] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [showFeedbackArray, setShowFeedbackArray] = useState(
    new Array(chatList.length).fill(false)
  );

  const pathname = window.location.pathname.split("/")[1];

  const toggleShowFeedback = (index) => {
    const updatedShowFeedbackArray = [...showFeedbackArray];
    updatedShowFeedbackArray[index] = !updatedShowFeedbackArray[index];
    setShowFeedbackArray(updatedShowFeedbackArray);
  };

  const handleClose = (event, reason) => {
    if (reason === MODEL_CLICKAWAY) {
      return;
    }
    setOpen(false);
  };
  const getChatFunction = (data) => {
    if (data) {
      dispatch(getChat({ data: data, chat: chatList, project: pathname }));
    }
  };
  const submitFeedbackFunction = (messageId) => {
    if (messageId)
      dispatch(
        submit_feedback({ id: messageId, feedback: FEEDBACK_MESSAGE_GOOD })
      );
  };

  const handleClick = (param) => {
    setTabActive(param);
  };

  const chatContainerRef = useRef(null);
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // Scroll to bottom whenever chatData updates
  useEffect(() => {
    scrollToBottom();
  }, [chatList]);

  useEffect(() => {
    if (feedback_res.length !== 0) {
      setModalShow(false);
      setOpen(true);
      const newChatList = chatList.map((obj, i) => {
        if (obj.id === feedback_res.id) {
          return {
            query: obj.query,
            response: obj.response,
            id: obj.id,
            drug_details: obj.drug_details,
            feedback: feedback_res.feedback,
          };
        } else {
          return obj;
        }
      });
      setChatList(newChatList);
    }
  }, [feedback_res]);

  useEffect(() => {
    if (chat.length !== 0) {
      setChatList([...chatList, chat]);
      setQuery("");
    }
    dispatch(chat_history());
  }, [chat]);

  const logout = () => {
    dispatch(logoutUser());
    window.localStorage.clear();
  };

  const handleMenuclick = () => {
    setHistoryActive(!historyActive);
  };

  const recognize = () => {
    setIsSpeaking(true);
  };

  const clearVoice = () => {
    setIsSpeaking(false);
  };

  return (
    <div style={{ backgroundColor: "" }} className="appBody">
      <LogoBar logout={logout} history_lists={history_lists} />
      <Box
        md={8}
        xs={8}
        style={{
          flex: "8",
        }}
        className="workArea "
      >
        <Container>
          <Box
            md={9}
            xs={12}
            className="titleWrap
          d-flex align-items-center justify-content-start"
          >
            <h5 className="mainTitle">{APP_NAME}</h5>
            {/* {historyActive ? (
              <CloseIcon className="historyMenu" onClick={handleMenuclick} />
            ) : (
              <MenuIcon className="historyMenu" onClick={handleMenuclick} />
            )} */}
          </Box>
          <Row>
            <Col>
              <Box className="chatMain chatareabg">
                <Box className="chatView">
                  <Box className="chatBox" ref={chatContainerRef}>
                    {chatList.length > 0 && (
                      chatList.map((obj, i) => {
                        return (
                          <Box
                            key={i}
                            // className={`answerBox ${i % 2 === 0 ? 'oddBox' : 'evenBox'}`}
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"30px"}
                          >
                            <Box
                              // style={{backgroundColor:"green"}}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                p: 2,
                              }}
                            >
                              <Box className="messageHead d-flex">
                                <h5>{obj.query}</h5>
                              </Box>
                              <Box
                                // style={{backgroundColor:"yellow"}}
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  pl: 1,
                                }}
                              >
                                <Avatar
                                  src={require("../../assets/dummyimg.png")}
                                  sx={{ width: 35, height: 35 }}
                                ></Avatar>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                m: 1,
                              }}
                            >
                              <Box
                                // style={{backgroundColor:"yellow"}}
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  pl: 1,
                                  pb: 2,
                                  pr: 1,
                                }}
                              >
                                <Avatar
                                  src={require("../../assets/inphronesis-chat-logo.png")}
                                  sx={{ width: 35, height: 35 }}
                                ></Avatar>
                              </Box>

                              <Box className="messageBody">
                                <Box className="messageText ">
                                  {obj.response}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent:'space-between',
                                    gap: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignContent: "flex-start",
                                      borderRadius: 1,
                                      gap: 1,
                                    }}
                                  >
                                    {
                                      obj?.drug_details && obj?.drug_details.length > 0 &&
                                        <Box className="messageRefer mb-2">
                                          Refer the links
                                        </Box>
                                    }

                                    {obj?.drug_details?.map(
                                      (drug_detail, i) => {
                                        return (
                                          <Box
                                            className="refBlock d-flex align-items-center"
                                            key={i}
                                            sx={{
                                              marginRight: "3px",
                                            }}
                                          >
                                            <Link
                                              href={drug_detail.drug_link}
                                              underline="hover"
                                              target="_blank"
                                            >
                                              {drug_detail.drug_name}
                                            </Link>
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Box>

                          {
                            obj?.drug_details && obj?.drug_details.length > 0 &&
                                  <Box 
                                //   style={{backgroundColor:"green"}} 
                                  className="footerBox g-10">
                                    <Box className="d-flex g-10">
                                      <IconButton
                                        style={{ color: "white" }}
                                        disableRipple
                                        className="thumbBtn"
                                        aria-label="thumb up"
                                        onClick={() =>
                                          obj?.feedback !==
                                            FEEDBACK_MESSAGE_GOOD &&
                                          submitFeedbackFunction(obj?.id)
                                        }
                                      >
                                        <ThumbUpIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{ color: "white" }}
                                        disableRipple
                                        className="thumbBtn"
                                        aria-label="thumb down"
                                        color="white"
                                        // onClick={() =>
                                        //   setSelectedMessageId(obj?.id) 
                                        //   || toggleShowFeedback(i)
                                        // }
                                      >
                                        <ThumbDownIcon />
                                      </IconButton>
                                    </Box>
                                    {showFeedbackArray[i] && (
                                      <Box className="feedbackBox d-flex align-items-center g-10">
                                        <Form.Control
                                          type="text"
                                          placeholder="Type your feedback here"
                                        />
                                        <Button className="feedbackSubmit">
                                          Submit
                                        </Button>
                                        <CloseIcon
                                          className="feedbackClose pointer"
                                          onClick={() => toggleShowFeedback(i)}
                                        />
                                      </Box>
                                    )}
                                  </Box>
}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })
                    ) 
                    // : (
                    //   <Box className="placeholderWrap d-flex align-items-center justify-content-center g-15 flex-column">
                    //     <h4>{APP_NAME}</h4>
                    //     <CommentIcon className="placeholderComment" />
                    //   </Box>
                    // )
                    }

                    {chat_loading && (
                      <Box className="dotloaderWrap">
                        <div class="button-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box className="chatInput mt-4  ">
                  <Box className="inputBox d-flex align-items-center g-5 w-100">
                    <Form.Group
                      className="w-100 mb-1"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        id="input"
                        placeholder={SEARCH_INPUT_PLACEHOLDER}
                        className="chatTextinput"
                        disabled={chat_loading}
                        onKeyPress={(e) => {
                          if (e.key === ENTER) {
                            getChatFunction(e.target.value);
                          }
                        }}
                      />
                    </Form.Group>
                    {isSpeaking && (
                      <Box id="bars">
                        <Box class="bar"></Box>
                        <Box class="bar"></Box>
                        <Box class="bar"></Box>
                        <Box class="bar"></Box>
                        <Box class="bar"></Box>
                      </Box>
                    )}

                    {!isSpeaking && (
                      <KeyboardVoiceIcon
                        sx={{ color: "#235CAB" }}
                        className="voice mx-1 pointer"
                        onClick={() => recognize()}
                      />
                    )}
                    {isSpeaking && (
                      <Box
                        className="black-cross mx-1"
                        onClick={() => clearVoice()}
                      >
                        ×
                      </Box>
                    )}
                    <Button
                      className="btnSend"
                      disabled={chat_loading}
                      onClick={() => getChatFunction(query)}
                    >
                      Send
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
      <History history_lists={history_lists} />
      <FeedbackModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={{ id: selectedMessageId }}
      />
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MuiAlert
          onClose={handleClose}
          severity="success"
          sx={{
            width: "100%",
          }}
        >
          Feedback updated successfully!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ChatBox;
