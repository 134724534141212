import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  Box,
  Avatar,
} from "@mui/material";
import Card from "@mui/material/Card";

const ProfileModel = (props) => {
  const { open, handleClose, logout } = props;

  return (
    <Dialog
    PaperProps={{ sx: {position: "absolute", left: 0, bottom: 0} }}
    sx={{
}}
      open={open}
      onClose={handleClose}
      aria-labelledby="unstyled-dialog-title"
      aria-describedby="unstyled-dialog-description"
    >

      <Card
          style={{
            // backgroundColor: 'yellow',
          width:"500px",
        }}
        open={open}
        sx={{ display: "flex", flexDirection: "row", }}
      >
        <Box
          md={4}
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "15px",
            borderRight: 1,
            borderColor: "#E5E5E5",
            p: 2,
          }}
        >
          <Avatar style={{objectFit:"cover"}} src={require('../../assets/dummyimg.png')} alt="Remy Sharp" sx={{ width: 100, height: 100 }}>
          </Avatar>
          <h3
            style={{ textDecorationLine: "underline", color: "#235CAB" }}
            className="fontSize1 pointer"
          >
            Update New Photo
          </h3>
        </Box>

        <Box
        // style={{backgroundColor:"lightblue"}}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            p: 2,
          }}
            >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              borderBottom: 1,
              borderColor: "#E5E5E5",
              pl:2,
              pr:2
            }}
          >
            <div className="fontSize2 pointer">Cape Dev</div>
            <h4 className="fontSize4 pointer">Admin</h4>
          </Box>

          <Box
          // style={{backgroundColor:"lightblue"}}
            sx={{
              display: "flex",
              flexDirection: "column",
              // gap: "3px",
              borderBottom: 1,
              borderColor: "#E5E5E5",
              pl:2,
              pr:2
            }}
          >
            <h4 className="fontSize5 pointer m-0">Notification</h4>
            <h4 className="fontSize5 pointer m-0">Change Password</h4>
            <h4 className="fontSize5 pointer m-0">Advance Setting</h4>
          </Box>
          <Box
            onClick={logout}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              pl:2,
              pr:2
            }}
          >
            <img
              className="pointer"
              style={{ height: "20px", width: "20px", objectFit: "cover" }}
              src={require("../../assets/logout.png")}
              alt="logout"
            />
            <h4 className="fontSize7 pointer m-0">Log Out</h4>
          </Box>
        </Box>
        <Box
        md={2}
        sm={12}
        // style={{backgroundColor: "green"}}
        sx={{
            display: "flex",
            justifyContent:"flex-end",
            gap: "15px",
            pl:8 ,
            pt:2
          }}
        >
            <img className="pointer" style={{height:"25px",width:"25px"}} 
            src={require('../../assets/close.png')} alt="close"
            onClick={handleClose}
            />
        </Box>
      </Card>
    </Dialog>
  );
};

ProfileModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProfileModel;

