import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import ProfileModel from '../ProfileModel/ProfileModel';

const LogoBar = (props) => {
    const {logout} = props;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setOpen(false);

    return (
        <div className='sidebarbg' style={{  paddingLeft: '20px',paddingTop:"10px", paddingRight: '20px', paddingBottom: '25px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: '0.7'
                }}>
            <div>
                <img src={require('../../assets/Logo.png')} style={{position:"absolute", objectFit: 'cover' }} alt="logo" />
            </div>
            <div className='pointer' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Avatar src={require('../../assets/dummyimg.png')} onClick={handleOpen}></Avatar>
                <h4 style={{margin:"0"}} className='fontSize7' onClick={handleOpen}>Cape</h4>
                <div>
                    <ProfileModel logout={logout} open={open} handleClose={handleClose} anchorEl={anchorEl} />
                </div>
            </div>
        </div>
    );
};

export default LogoBar;