import * as yup from "yup";
import {
  USERNAME_REQUIRED_ERROR,
  PASSWORD_REQUIRED_ERROR,
} from "./Constants";

export const LOGIN_SCHEMA = yup.object().shape({
    username: yup.string().trim().required(USERNAME_REQUIRED_ERROR),
    password: yup.string().trim().required(PASSWORD_REQUIRED_ERROR),
  });
  