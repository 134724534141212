import { useEffect } from "react";
// import ChatBot from './components/ChatBot';
import ChatBox from "./components/ChatBox/ChatBox";
import Login from './components/login';
import { setUserToken } from "../src/reducers/login";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import './App.css';
import ChatBot from "./components/ChatBot";

function App() {
  const dispatch = useDispatch();
  const { user, } = useSelector((state) => state.login);

  useEffect(() => {
    const token = window.localStorage.getItem("token");

    if (token) {
      dispatch(setUserToken({ token: token }));
    }
  }, []);

  return (
    <div className="App">
      {user?.token ? <ChatBox /> : <Login />}
    </div>
  );
}

export default App;
