import {
  INVALID_SECRET_URL,
  UNEXPECTED_GETURL_ERROR,
  THROW_ERROR,
} from "../constants/Constants";
import { API_URL } from "../constants/Config";
import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";

const api = {
  fetch: (url, config) => fetch(`${API_URL}${url}`, config),
};

const initialState = {
  chat: [],
  chat_loading: false,
  feedback_res: [],
  feedback_loading: false,
  history_loading: false,
  history_lists : [],
  error: false,
};

export const getChat = createAsyncThunk(
  "bot/get_chat",
  (arg, { getState }) => {
    const { user } = getState()?.login;
    const token = user.token;
    if (token) {
      return api
        .fetch(`/chat`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
          method: "POST",
          body: JSON.stringify({ query: arg.data, chat: arg.chat, project: arg.project }),
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(() => {
              throw THROW_ERROR;
            });
          }
        })
        .catch((err) => {
          const errorMsg =
            err === THROW_ERROR
              ? INVALID_SECRET_URL
              : UNEXPECTED_GETURL_ERROR + err;
          throw errorMsg;
        });
    }
  }
);

export const submit_feedback = createAsyncThunk(
  "bot/submit_feedback",
  (arg, { getState }) => {
    const { user } = getState()?.login;
    const token = user.token;
    if (token) {
      return api
        .fetch(`/feedback`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
          method: "POST",
          body: JSON.stringify(arg),
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(() => {
              throw THROW_ERROR;
            });
          }
        })
        .catch((err) => {
          const errorMsg =
            err === THROW_ERROR
              ? INVALID_SECRET_URL
              : UNEXPECTED_GETURL_ERROR + err;
          throw errorMsg;
        });
    }
  }
);

export const chat_history = createAsyncThunk(
  "bot/chat_history",
  (arg, { getState }) => {
    const { user } = getState()?.login;
    const token = user.token;
    if (token) {
      return api
        .fetch(`/history`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
          method: "GET",
          body: JSON.stringify(arg),
        })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(() => {
              throw THROW_ERROR;
            });
          }
        })
        .catch((err) => {
          const errorMsg =
            err === THROW_ERROR
              ? INVALID_SECRET_URL
              : UNEXPECTED_GETURL_ERROR + err;
          throw errorMsg;
        });
    }
  }
);


const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetChat: (state) => {
      state.chat = [];
    },
  },
  extraReducers: {
    [getChat.pending]: (state) => {
      state.chat_loading = true;
    },
    [getChat.fulfilled]: (state, action) => {
      state.chat_loading = false;
      state.chat = action.payload;
    },
    [getChat.rejected]: (state, action) => {
      state.chat_loading = false;
      state.error = action.error;
    },

    [submit_feedback.pending]: (state) => {
      state.feedback_loading = true;
    },
    [submit_feedback.fulfilled]: (state, action) => {
      state.feedback_loading = false;
      state.feedback_res = action.payload;
    },
    [submit_feedback.rejected]: (state, action) => {
      state.feedback_loading = false;
      state.error = action.error;
    },

    [chat_history.pending]: (state) => {
      state.history_loading = true;
    },
    // [chat_history.fulfilled]: (state, action) => {
    //   console.log("Action",action)
    //   state.history_lists = Array.isArray(action.payload) ? action.payload : [];
    //   state.history_loading = false;
    // },
    
    [chat_history.fulfilled]: (state, action) => {
      console.log("Action", action);
      const newValue = action.payload
      state.history_lists = newValue;
      state.history_loading = false;
    },
    [chat_history.rejected]: (state, action) => {
      state.history_loading = false;
      state.error = action.error;
    },
  },
});

export const { resetChat, } = chatSlice.actions;
export default chatSlice.reducer;
