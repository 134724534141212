import { configureStore, combineReducers } from "@reduxjs/toolkit";
import chat from "../reducers/chat";
import login from "../reducers/login";
import { LOGOUT } from "../constants/Constants"

const combinedReducer = combineReducers({
  chat: chat,
  login: login,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
