import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector, } from "react-redux";
import { userLogin, resetError } from "../reducers/login";

import {
    LOGIN_SCHEMA,
} from "../constants/validationSchema";
import Logo from '../assets/Logo.png'

import './style.css'

const Login = () => {
    const dispatch = useDispatch();
    const {
        loading,
        error,
    } = useSelector((state) => state.login);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        if (error) {
            setShowAlert(true);
            setAlertMessage(error);
            dispatch(resetError());
        }
    }, [error]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowAlert(false);
    };

    return (
        <div className='boxBody'>
            <Snackbar
                open={showAlert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MuiAlert
                    onClose={handleClose}
                    severity={"error"}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
            <div className="loginDiv text-center">
                <img src={Logo} className="loginLogoImage" alt="logo not found" />

                <Formik
                    validationSchema={LOGIN_SCHEMA}
                    initialValues={{ username: "", password: "" }}
                    onSubmit={(values) => {
                        dispatch(
                            userLogin({
                                username: values.username,
                                password: values.password,
                            })
                        );
                    }}
                >
                    {({ errors, handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} className="loginFormContainer">
                            <div className='formItems'>
                                <Form.Group>
                                    <TextField
                                        name="username"
                                        error={errors?.username ? true : false}
                                        label="Username"
                                        id="loginForgetPasswordInput"
                                        helperText={errors?.username ? errors?.username : ""}
                                        onChange={handleChange}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <TextField
                                        name="password"
                                        type="password"
                                        error={errors?.password ? true : false}
                                        label="Password"
                                        id="loginForgetPasswordInput"
                                        helperText={errors?.password ? errors?.password : ""}
                                        onChange={handleChange}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <Button
                                    variant="contained"
                                    className="loginButton"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? "Loading…" : "SIGN IN"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Login;