import React from "react";
import {
  Container,
} from "react-bootstrap";
import { Box } from "@mui/material";
import { historyData } from "../../constants/Constants";
import { useSelector } from "react-redux";


const History = (props) => {
  const {history_lists} = props
    const {
        chat,
        chat_loading,
        feedback_res,
      } = useSelector((state) => state.chat);

  return (
    <div style={{ display: "flex", flex: "2.5", backgroundColor: "#F5F7FB" }}>
      <Container>
        <Box
          md={9}
          xs={12}
          className="titleWrap
          d-flex align-items-center justify-content-start"
        >
          <h5 className="mainTitle">History</h5>
        </Box>
        <Box
          className="historycontainer"
          style={{
            overflow: "auto",
            maxHeight: "600px",
          }}
        >

          {history_lists && history_lists.length > 0 && (
            <Box
              sx={{ borderBottom: 1, borderColor: "#E6E6E6",  pt:2,pb:2  }}
              md={9}
              xs={12}
              className=""
            >
              <h5 style={{ color: "#646464" }} className="fontSize8">
                Previous 30 Days
              </h5>
              {history_lists.map((data) => {
                return (
                  <h5 key={data.timestamp} className="fontSize6 pointer">
                    {data.question.length > 35
                      ? `${data.question.slice(0, 35)}...`
                      : data.question}
                  </h5>
                );
              })}
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default History;
