export const UNEXPECTED_GETURL_ERROR =
    "An unexpected error has occured in getUrl.  If this error continues, please contact support"
export const THROW_ERROR = "invalid error"
export const INVALID_SECRET_URL =
    "An error has occured granting access to the Freightflows Analytics Platform. If this error continues, please contact support"
export const ENTER = "Enter"
export const SEARCH_INPUT_PLACEHOLDER = "Type your message here"
export const MODEL_INPUT_PLACEHOLDER = "Type your feedback here"
export const FEEDBACK_MESSAGE_BAD = "bad"
export const FEEDBACK_MESSAGE_GOOD = "good"
export const MODEL_CLICKAWAY = "clickaway"

export const USERNAME_REQUIRED_ERROR = 'Username is Required';
export const PASSWORD_REQUIRED_ERROR = 'Password is Required';

export const INVALID_PASSWORD =
  "Invalid username or password. Please try again.";
export const UNEXPECTED_ERROR =
  "An unexpected error has occured.  If this error continues, please contact support";
export const LOGOUT = "LOGOUT_USER";
export const LOGO_URL = "https://www.inphronesis.com/hs-fs/hubfs/inPhronesis%20Logo_Horizontal.png?width=182&name=inPhronesis%20Logo_Horizontal.png"
export const APP_NAME = "Ask inVision";


export const historyData=[
  {
      "user": "capestart",
      "question": "When is the next approval for xeljanz in sJIA",
      "answer": "The next approval for Xeljanz in systemic juvenile idiopathic arthritis (sJIA) is expected in the second quarter of 2025 in both the U.S. and EU regions.",
      "feedback": "",
      "link": "https://invision.inphronesis.com/projects/ABB48/drugs/150",
      "timestamp": "2024-04-08T10:03:45.322857"
  },
  {
      "user": "capestart",
      "question": "When is the next approval for UC in the US?",
      "answer": "The next approval for Ulcerative Colitis (UC) in the US is expected to be for the drug Tremfya in the first quarter of 2025.",
      "feedback": "",
      "link": "https://invision.inphronesis.com/projects/ABB48/drugs/116",
      "timestamp": "2024-04-07T19:41:26.671624"
  },
  {
      "user": "capestart",
      "question": "What Events Are Expected In Alopecia Areata In 2025?",
      "answer": "In 2025, several significant events are expected in the field of Alopecia Areata. The phase 3 trial of the drug Rinvoq is expected to have its primary completion in May 2025, with a readout expected in the third quarter of 2025. However, the approval of Rinvoq in the US and EU regions is expected in 2027. Another drug, Sotyktu, is expected to get approval in the EU region for Alopecia Areata in the second quarter of 2028. The drug Velsipity is expected to have its phase 2 trial readout in the first quarter of 2024. The approval of Velsipity in the US and EU regions is expected in the second quarter of 2027. Other drugs like Litfulo, Olumiant, Deuruxolitinib, and others are also being studied for Alopecia Areata and are expected to have significant events in 2025.",
      "feedback": "",
      "link": "https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/160, https://invision.inphronesis.com/projects/ABB48/drugs/155, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/1490, https://invision.inphronesis.com/projects/ABB48/drugs/1491",
      "timestamp": "2024-04-08T10:40:10.143905"
  },
  {
      "user": "capestart",
      "question": "What JAK inhibitors are being studied for Crohn's disease?",
      "answer": "The JAK inhibitors being studied for Crohn's disease include Zasocitinib, JNJ-78934804, Rinvoq, Jyseleca, Ivarmacitinib (PO), Stelara, Entyvio (SC), Zasocitinib, Entyvio (IV), Litfulo, JNJ-78934804, VTX958, Tulisokibart, Skyrizi, Rinvoq, Entyvio (SC), Ivarmacitinib (PO), Entyvio (IV), Litfulo, JNJ-78934804, Tulisokibart, Skyrizi, Rinvoq, Entyvio (SC), Ivarmacitinib (PO), and Rinvoq.",
      "feedback": "",
      "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1660, https://invision.inphronesis.com/projects/ABB48/drugs/1976, https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/102, https://invision.inphronesis.com/projects/ABB48/drugs/174, https://invision.inphronesis.com/projects/ABB48/drugs/111, https://invision.inphronesis.com/projects/ABB48/drugs/109, https://invision.inphronesis.com/projects/ABB48/drugs/108, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/1684, https://invision.inphronesis.com/projects/ABB48/drugs/138, https://invision.inphronesis.com/projects/ABB48/drugs/114",
      "timestamp": "2024-04-08T10:38:24.621097"
  },
  {
      "user": "capestart",
      "question": "When will kevzara be approved?",
      "answer": "Kevzara is expected to be approved in the EU for the treatment of Systemic Juvenile Idiopathic Arthritis (sJIA) in the first quarter of 2028. Please note that this is an estimate and the actual approval may vary based on regulatory processes and other factors.",
      "feedback": "",
      "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1516",
      "timestamp": "2024-03-27T10:30:59.861381"
  },
  {
      "user": "capestart",
      "question": "What JAK inhibitors are being studied for Crohn's disease?",
      "answer": "The JAK inhibitors being studied for Crohn's disease include Zasocitinib, JNJ-78934804, Rinvoq, Jyseleca, Ivarmacitinib (PO), Stelara, Entyvio (SC), Litfulo, Tulisokibart, and Skyrizi.",
      "feedback": "",
      "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1660, https://invision.inphronesis.com/projects/ABB48/drugs/1976, https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/102, https://invision.inphronesis.com/projects/ABB48/drugs/174, https://invision.inphronesis.com/projects/ABB48/drugs/111, https://invision.inphronesis.com/projects/ABB48/drugs/109, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/138, https://invision.inphronesis.com/projects/ABB48/drugs/114",
      "timestamp": "2024-03-21T17:21:28.582072"
  },
  {
      "user": "capestart",
      "question": "When will kevzara be approved?",
      "answer": "Kevzara is expected to be approved in the EU for Systemic Juvenile Idiopathic Arthritis in 2028-Q1. It is also anticipated to be approved in the US for the same indication in 2027-Q4.",
      "feedback": "",
      "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1516",
      "timestamp": "2024-03-21T07:58:27.658080"
  },
  {
      "user": "capestart",
      "question": "When will Fitusiran be approved?",
      "answer": "The drug Fitusiran is expected to be approved in the US for Hemophilia B without inhibitors in August 2025. Additionally, it is projected to receive pediatric approval for Hemophilia A & B with or without inhibitors in December 2026.",
      "feedback": "",
      "link": "https://invision.inphronesis.com/projects/DMSN1/drugs/1365",
      "timestamp": "2024-03-19T14:26:41.483346"
  },
  ////////////

  {
    "user": "capestart",
    "question": "When is the next approval for UC in the US?",
    "answer": "The next approval for Ulcerative Colitis (UC) in the US is expected to be for the drug Tremfya in the first quarter of 2025.",
    "feedback": "",
    "link": "https://invision.inphronesis.com/projects/ABB48/drugs/116",
    "timestamp": "2024-03-27T19:41:26.671624"
},
{
    "user": "capestart",
    "question": "What Events Are Expected In Alopecia Areata In 2025?",
    "answer": "In 2025, several significant events are expected in the field of Alopecia Areata. The phase 3 trial of the drug Rinvoq is expected to have its primary completion in May 2025, with a readout expected in the third quarter of 2025. However, the approval of Rinvoq in the US and EU regions is expected in 2027. Another drug, Sotyktu, is expected to get approval in the EU region for Alopecia Areata in the second quarter of 2028. The drug Velsipity is expected to have its phase 2 trial readout in the first quarter of 2024. The approval of Velsipity in the US and EU regions is expected in the second quarter of 2027. Other drugs like Litfulo, Olumiant, Deuruxolitinib, and others are also being studied for Alopecia Areata and are expected to have significant events in 2025.",
    "feedback": "",
    "link": "https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/160, https://invision.inphronesis.com/projects/ABB48/drugs/155, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/1490, https://invision.inphronesis.com/projects/ABB48/drugs/1491",
    "timestamp": "2024-03-28T10:40:10.143905"
},
{
    "user": "capestart",
    "question": "What JAK inhibitors are being studied for Crohn's disease?",
    "answer": "The JAK inhibitors being studied for Crohn's disease include Zasocitinib, JNJ-78934804, Rinvoq, Jyseleca, Ivarmacitinib (PO), Stelara, Entyvio (SC), Zasocitinib, Entyvio (IV), Litfulo, JNJ-78934804, VTX958, Tulisokibart, Skyrizi, Rinvoq, Entyvio (SC), Ivarmacitinib (PO), Entyvio (IV), Litfulo, JNJ-78934804, Tulisokibart, Skyrizi, Rinvoq, Entyvio (SC), Ivarmacitinib (PO), and Rinvoq.",
    "feedback": "",
    "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1660, https://invision.inphronesis.com/projects/ABB48/drugs/1976, https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/102, https://invision.inphronesis.com/projects/ABB48/drugs/174, https://invision.inphronesis.com/projects/ABB48/drugs/111, https://invision.inphronesis.com/projects/ABB48/drugs/109, https://invision.inphronesis.com/projects/ABB48/drugs/108, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/1684, https://invision.inphronesis.com/projects/ABB48/drugs/138, https://invision.inphronesis.com/projects/ABB48/drugs/114",
    "timestamp": "2024-03-28T10:38:24.621097"
},
{
    "user": "capestart",
    "question": "When will kevzara be approved?",
    "answer": "Kevzara is expected to be approved in the EU for the treatment of Systemic Juvenile Idiopathic Arthritis (sJIA) in the first quarter of 2028. Please note that this is an estimate and the actual approval may vary based on regulatory processes and other factors.",
    "feedback": "",
    "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1516",
    "timestamp": "2024-03-27T10:30:59.861381"
},
{
    "user": "capestart",
    "question": "What JAK inhibitors are being studied for Crohn's disease?",
    "answer": "The JAK inhibitors being studied for Crohn's disease include Zasocitinib, JNJ-78934804, Rinvoq, Jyseleca, Ivarmacitinib (PO), Stelara, Entyvio (SC), Litfulo, Tulisokibart, and Skyrizi.",
    "feedback": "",
    "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1660, https://invision.inphronesis.com/projects/ABB48/drugs/1976, https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/102, https://invision.inphronesis.com/projects/ABB48/drugs/174, https://invision.inphronesis.com/projects/ABB48/drugs/111, https://invision.inphronesis.com/projects/ABB48/drugs/109, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/138, https://invision.inphronesis.com/projects/ABB48/drugs/114",
    "timestamp": "2024-03-21T17:21:28.582072"
},
{
    "user": "capestart",
    "question": "When will kevzara be approved?",
    "answer": "Kevzara is expected to be approved in the EU for Systemic Juvenile Idiopathic Arthritis in 2028-Q1. It is also anticipated to be approved in the US for the same indication in 2027-Q4.",
    "feedback": "",
    "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1516",
    "timestamp": "2024-03-21T07:58:27.658080"
},
{
    "user": "capestart",
    "question": "When will Fitusiran be approved?",
    "answer": "The drug Fitusiran is expected to be approved in the US for Hemophilia B without inhibitors in August 2025. Additionally, it is projected to receive pediatric approval for Hemophilia A & B with or without inhibitors in December 2026.",
    "feedback": "",
    "link": "https://invision.inphronesis.com/projects/DMSN1/drugs/1365",
    "timestamp": "2024-03-19T14:26:41.483346"
},


{
  "user": "capestart",
  "question": "When is the next approval for UC in the US?",
  "answer": "The next approval for Ulcerative Colitis (UC) in the US is expected to be for the drug Tremfya in the first quarter of 2025.",
  "feedback": "",
  "link": "https://invision.inphronesis.com/projects/ABB48/drugs/116",
  "timestamp": "2024-03-27T19:41:26.671624"
},
{
  "user": "capestart",
  "question": "What Events Are Expected In Alopecia Areata In 2025?",
  "answer": "In 2025, several significant events are expected in the field of Alopecia Areata. The phase 3 trial of the drug Rinvoq is expected to have its primary completion in May 2025, with a readout expected in the third quarter of 2025. However, the approval of Rinvoq in the US and EU regions is expected in 2027. Another drug, Sotyktu, is expected to get approval in the EU region for Alopecia Areata in the second quarter of 2028. The drug Velsipity is expected to have its phase 2 trial readout in the first quarter of 2024. The approval of Velsipity in the US and EU regions is expected in the second quarter of 2027. Other drugs like Litfulo, Olumiant, Deuruxolitinib, and others are also being studied for Alopecia Areata and are expected to have significant events in 2025.",
  "feedback": "",
  "link": "https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/160, https://invision.inphronesis.com/projects/ABB48/drugs/155, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/1490, https://invision.inphronesis.com/projects/ABB48/drugs/1491",
  "timestamp": "2024-03-28T10:40:10.143905"
},
{
  "user": "capestart",
  "question": "What JAK inhibitors are being studied for Crohn's disease?",
  "answer": "The JAK inhibitors being studied for Crohn's disease include Zasocitinib, JNJ-78934804, Rinvoq, Jyseleca, Ivarmacitinib (PO), Stelara, Entyvio (SC), Zasocitinib, Entyvio (IV), Litfulo, JNJ-78934804, VTX958, Tulisokibart, Skyrizi, Rinvoq, Entyvio (SC), Ivarmacitinib (PO), Entyvio (IV), Litfulo, JNJ-78934804, Tulisokibart, Skyrizi, Rinvoq, Entyvio (SC), Ivarmacitinib (PO), and Rinvoq.",
  "feedback": "",
  "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1660, https://invision.inphronesis.com/projects/ABB48/drugs/1976, https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/102, https://invision.inphronesis.com/projects/ABB48/drugs/174, https://invision.inphronesis.com/projects/ABB48/drugs/111, https://invision.inphronesis.com/projects/ABB48/drugs/109, https://invision.inphronesis.com/projects/ABB48/drugs/108, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/1684, https://invision.inphronesis.com/projects/ABB48/drugs/138, https://invision.inphronesis.com/projects/ABB48/drugs/114",
  "timestamp": "2024-03-28T10:38:24.621097"
},
{
  "user": "capestart",
  "question": "When will kevzara be approved?",
  "answer": "Kevzara is expected to be approved in the EU for the treatment of Systemic Juvenile Idiopathic Arthritis (sJIA) in the first quarter of 2028. Please note that this is an estimate and the actual approval may vary based on regulatory processes and other factors.",
  "feedback": "",
  "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1516",
  "timestamp": "2024-03-27T10:30:59.861381"
},
{
  "user": "capestart",
  "question": "What JAK inhibitors are being studied for Crohn's disease?",
  "answer": "The JAK inhibitors being studied for Crohn's disease include Zasocitinib, JNJ-78934804, Rinvoq, Jyseleca, Ivarmacitinib (PO), Stelara, Entyvio (SC), Litfulo, Tulisokibart, and Skyrizi.",
  "feedback": "",
  "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1660, https://invision.inphronesis.com/projects/ABB48/drugs/1976, https://invision.inphronesis.com/projects/ABB48/drugs/156, https://invision.inphronesis.com/projects/ABB48/drugs/102, https://invision.inphronesis.com/projects/ABB48/drugs/174, https://invision.inphronesis.com/projects/ABB48/drugs/111, https://invision.inphronesis.com/projects/ABB48/drugs/109, https://invision.inphronesis.com/projects/ABB48/drugs/173, https://invision.inphronesis.com/projects/ABB48/drugs/138, https://invision.inphronesis.com/projects/ABB48/drugs/114",
  "timestamp": "2024-03-21T17:21:28.582072"
},
{
  "user": "capestart",
  "question": "When will kevzara be approved?",
  "answer": "Kevzara is expected to be approved in the EU for Systemic Juvenile Idiopathic Arthritis in 2028-Q1. It is also anticipated to be approved in the US for the same indication in 2027-Q4.",
  "feedback": "",
  "link": "https://invision.inphronesis.com/projects/ABB48/drugs/1516",
  "timestamp": "2024-03-21T07:58:27.658080"
},
{
  "user": "capestart",
  "question": "When will Fitusiran be approved?",
  "answer": "The drug Fitusiran is expected to be approved in the US for Hemophilia B without inhibitors in August 2025. Additionally, it is projected to receive pediatric approval for Hemophilia A & B with or without inhibitors in December 2026.",
  "feedback": "",
  "link": "https://invision.inphronesis.com/projects/DMSN1/drugs/1365",
  "timestamp": "2024-03-19T14:26:41.483346"
},


]